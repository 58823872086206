<template>
  <b-card>
    <validation-observer
      ref="userEdit"
      v-slot="{invalid}"
    >
      <!-- media -->
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <b-img
              ref="previewEl"
              rounded
              :src="image"
              height="80"
            />
          </b-link>
          <!--/ avatar -->
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <!-- upload button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            Nova foto
          </b-button>

          <b-form-file
            ref="refInputEl"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @change="setImage"
          />
          <!--/ upload button -->

          <!-- adjust crop -->
          <!-- upload button -->
          <b-button
            v-if="imgSrc"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs['crop-image'].show()"
          >
            Ajustar photo
          </b-button>
          <!-- /adjust crop -->
        </b-media-body>
      </b-media>
      <!--/ media -->
      <b-modal
        ref="crop-image"
        hide-footer
        title="Cortar imagem"
        size="md"
      >
        <b-row>
          <b-col
            cols="12"
            md="7"
            lg="7"
            sm="12"
          >
            <section class="cropper-area">
              <div class="img-cropper">
                <vue-cropper
                  ref="cropper"
                  :aspect-ratio="1 / 1"
                  :src="imgSrc"
                  preview=".preview"
                />
              </div>
              <div class="actions">
                <a
                  href="#"
                  role="button"
                  @click.prevent="zoom(0.2)"
                >
                  Zoom
                  <feather-icon
                    icon="ZoomInIcon"
                    size="21"
                    class="stroke-current"
                  />
                </a>
                <a
                  href="#"
                  role="button"
                  @click.prevent="zoom(-0.2)"
                >
                  Zoom
                  <feather-icon
                    icon="ZoomOutIcon"
                    size="21"
                    class="stroke-current"
                  />
                </a>
                <a
                  href="#"
                  role="button"
                  @click.prevent="move(-10, 0)"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                    size="21"
                    class="stroke-current"
                  />
                  Mover
                </a>
                <a
                  href="#"
                  role="button"
                  @click.prevent="move(10, 0)"
                >
                  Mover
                  <feather-icon
                    icon="ArrowRightIcon"
                    size="21"
                    class="stroke-current"
                  />
                </a>
                <a
                  href="#"
                  role="button"
                  @click.prevent="move(0, -10)"
                >
                  <feather-icon
                    icon="ArrowUpIcon"
                    size="21"
                    class="stroke-current"
                  />
                  Mover
                </a>
                <a
                  href="#"
                  role="button"
                  @click.prevent="move(0, 10)"
                >
                  Mover
                  <feather-icon
                    icon="ArrowDownIcon"
                    size="21"
                    class="stroke-current"
                  />
                </a>
                <a
                  href="#"
                  role="button"
                  @click.prevent="rotate(-90)"
                >
                  <feather-icon
                    icon="CornerUpLeftIcon"
                    size="21"
                    class="stroke-current"
                  />
                  Girar
                </a>
                <a
                  href="#"
                  role="button"
                  @click.prevent="rotate(+90)"
                >
                  Girar
                  <feather-icon
                    icon="CornerUpRightIcon"
                    size="21"
                    class="stroke-current"
                  />
                </a>
                <!-- <a
                ref="flipX"
                href="#"
                role="button"
                @click.prevent="flipX"
              >
                Flip X
              </a>
              <a
                ref="flipY"
                href="#"
                role="button"
                @click.prevent="flipY"
              >
                Flip Y
              </a> -->

                <!-- <a
                href="#"
                role="button"
                @click.prevent="reset"
              >
                Reset
              </a> -->
                <!-- <a
                href="#"
                role="button"
                @click.prevent="getData"
              >
                Get Data
              </a> -->
                <!-- <a
                href="#"
                role="button"
                @click.prevent="setData"
              >
                Set Data
              </a> -->
                <!-- <a
                href="#"
                role="button"
                @click.prevent="getCropBoxData"
              >
                Get CropBox Data
              </a>
              <a
                href="#"
                role="button"
                @click.prevent="setCropBoxData"
              >
                Set CropBox Data
              </a> -->
                <a
                  href="#"
                  class="btn btn-info"
                  style="padding: 5px 15px;"
                  @click="$refs.refInputEl.$el.click()"
                >
                  Alterar imagem
                </a>
                <a
                  href="#"
                  class="btn btn-success"
                  variant="success"
                  @click.prevent="cropImage"
                >
                  Cortar
                </a>
              </div>
            </section>
          </b-col>

          <b-col
            cols="12"
            md="3"
            lg="4"
            sm="12"
          >
            <section class="preview-area">
              <p>Pré-visualização</p>
              <div class="preview">
                <div class="cropped-image">
                  <img
                    v-if="cropImg"
                    :src="cropImg"
                    alt="Cropped Image"
                  >
                  <div
                    v-else
                    class="crop-placeholder"
                  />
                </div>
              </div>
            </section>
          </b-col>
        </b-row>
      </b-modal>
      <!-- form -->
      <b-form
        class="mt-2"
        @submit.prevent="updateUser()"
      >
        <b-row>
          <!-- Field: Username -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="username"
              vid="username"
              rules="required|alpha_spaces|min:3"
            >
              <b-form-group
                label="Nome Completo"
                label-for="username"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="username"
                  v-model="user.name"
                  name="name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Email -->
          <b-col
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="email"
              vid="email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="user.email"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            md="4"
          >
            <validation-provider
              v-slot="{ errors }"
              name="birthDate"
              vid="birthDate"
              rules="required"
            >
              <b-form-group
                label="Data de nascimento"
                label-for="birthdate"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <flat-pickr
                  v-model="user.birthDate"
                  class="form-control"
                  :config="{ dateFormat: 'd/m/Y'}"
                  placeholder="YYYY-MM-DD"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: CPF -->
          <b-col
            v-if="!nationality"
            cols="12"
            md="4"
          >
            <validation-provider
              v-slot="{ errors }"
              name="cpf"
              vid="cpf"
              rules="required_if:nationality,|integer|digits:11"
            >
              <b-form-group
                label="CPF"
                label-for="cpf"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="cpf"
                  v-model="user.cpf"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: foreign document -->
          <b-col
            v-if="nationality"
            cols="6"
            md="4"
          >
            <validation-provider
              v-slot="{ errors }"
              name="foreignDocument"
              vid="foreignDocument"
              rules="required_if:nationaly,Não sou brasileiro"
            >
              <b-form-group
                label="Documento estrangeiro"
                label-for="foreignDocument"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="foreignDocument"
                  v-model="user.others"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
            lg="4"
          >
            <validation-provider
              name="nationality"
              vid="nationality"
              rules=""
            >
              <b-form-group
                label="Nacionalidade"
                label-for="nationality"
                label-class="mb-1"
              >
                <b-form-checkbox-group
                  id="nationality"
                  v-model="nationality"
                  :options="nationalityOptions"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: profession -->
        </b-row>
        <b-row>
          <!-- Field: Mobile -->
          <b-col
            cols="4"
            md="4"
            lg="4"
          >
            <validation-provider
              v-slot="{ errors }"
              name="phone"
              vid="phone"
              rules="required"
            >
              <b-form-group
                label="Celular"
                label-for="phone"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <vue-tel-input
                  id="phone"
                  v-model="user.phone"
                  name="phone"
                  :style="errors.length > 0 ? 'border-color: red;' : ''"
                  style="height:37px; border-radius:6px;"
                  :default-country="country"
                  :input-options="phoneOptions"
                  mode="international"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="invalid"
            >
              Salvar mudanças
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import { mapActions } from 'vuex'

import VueCropper from 'vue-cropperjs'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    flatPickr,
    // validations
    VueCropper,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      image: '',
      imgSrc: '',
      cropImg: '',
      nationality: null,
      user: {
        name: '',
        cpf: '',
        others: '',
        crm: '',
        crmUf: '',
        profession: '',
        photo: '',
        phone: '',
        birthDate: ''
      },
      country: 'BR',
      avatar: ('@/assets/images/pages/register-v2.svg'),
      // options
      states: [
        { value: '', text: 'Selecionar estado' },
        { value: 'AC', text: 'AC - Acre' },
        { value: 'AL', text: 'AL - Alagoas' },
        { value: 'AP', text: 'AP - Amapá' },
        { value: 'AM', text: 'AM - Amazonas' },
        { value: 'BA', text: 'BA - Bahia' },
        { value: 'CE', text: 'CE - Ceará' },
        { value: 'DF', text: 'DF - Distrito Federal' },
        { value: 'ES', text: 'ES - Espírito Santo' },
        { value: 'GO', text: 'GO - Goiás' },
        { value: 'MA', text: 'MA - Maranhão' },
        { value: 'MT', text: 'MT - Mato Grosso' },
        { value: 'MS', text: 'MS - Mato Grosso do Sul' },
        { value: 'MG', text: 'MG - Minas Gerais' },
        { value: 'PA', text: 'PA - Pará' },
        { value: 'PB', text: 'PB - Paraíba' },
        { value: 'PR', text: 'PR - Paraná' },
        { value: 'PE', text: 'PE - Pernambuco' },
        { value: 'PI', text: 'PI - Piauí' },
        { value: 'RJ', text: 'RJ - Rio de Janeiro' },
        { value: 'RN', text: 'RN - Rio Grande do Norte' },
        { value: 'RS', text: 'RS - Rio Grande do Sul' },
        { value: 'RO', text: 'RO - Rondônia' },
        { value: 'RR', text: 'RR - Roraima' },
        { value: 'SC', text: 'SC - Santa Catarina' },
        { value: 'SP', text: 'SP - São Paulo' },
        { value: 'SE', text: 'SE - Sergipe' },
        { value: 'TO', text: 'TO - Tocantins' },
      ],
      nationalityOptions: [
        { value: 'Others', text: 'Não sou brasileiro' },
      ],
      // validation
      required,
      phoneOptions: {
        placeholder: ''
      }
    }
  },
  computed: {
    userState() {
      return this.$store.state.auth.user
    },
    phone() {
      return this.user.phone
    },
  },
  watch: {
    nationality() {
      this.cpf = ''
      this.others = ''
    },
    phone(value) {
      console.log(value)

      if (this.user.phone) {
        // eslint-disable-next-line no-useless-escape
        this.user.phone = value.replace(/[a-zA-Z!@#$%^&*()\-=\[\]{};':"\\|,<>\/?]*$/, '')
      }
    },
    userState() {
      this.user.name = this.userState.name
      this.user.cpf = this.userState.cpf
      this.user.others = this.userState.other_doc
      this.user.crm = this.userState.crm
      this.user.crm_uf = this.userState.crm_uf
      this.user.profession = this.userState.profession
      this.user.photo = this.userState.photo
      this.user.phone = this.userState.phone
      this.user.birthDate = this.formatDate(this.userState.birthDate)
      if (this.userState.photo !== null) {
        this.validationImage(this.user.photo)
      }
      this.user.cep = this.userState.address.cep
      this.user.street = this.userState.address.street
      this.user.number = this.userState.address.number
      this.user.neighborhood = this.userState.address.neighborhood
      this.user.city = this.userState.address.city
      this.user.state = this.userState.address.state
      this.user.country = this.userState.address.country
      this.user.complemento = this.userState.address.complemento
    },
    user() {
      this.user.name = this.userState.name
      this.user.cpf = this.userState.cpf
      this.user.others = this.userState.other_doc
      this.user.crm = this.userState.crm
      this.user.crm_uf = this.userState.crm_uf
      this.user.profession = this.userState.profession
      this.user.photo = this.userState.photo
      this.user.phone = this.userState.phone
      this.user.birthDate = this.formatDate(this.userState.birthDate)
      if (this.userState.photo !== null) {
        this.validationImage(this.user.photo)
      }
      this.user.cep = this.userState.address.cep
      this.user.street = this.userState.address.street
      this.user.number = this.userState.address.number
      this.user.neighborhood = this.userState.address.neighborhood
      this.user.city = this.userState.address.city
      this.user.state = this.userState.address.state
      this.user.country = this.userState.address.country
      this.user.complemento = this.userState.address.complemento
    },
    imgSrc() {
      // this.$refs['crop-image'].show()
    },
  },
  mounted() {
    this.user = this.userState
  },
  methods: {
    ...mapActions('profile', ['ActionUpdateUser']),
    ...mapActions('auth', [
      'ActionSetUser',
    ]),
    formatDate(date) {
      const d = date.split('/')
      return `${d[2]}-${d[1]}-${d[0]}`
    },
    validationImage(url) {
      const path = `${process.env.VUE_APP_API_BASE}/storage/image/${url}`
      const http = new XMLHttpRequest()
      http.open('HEAD', path, false)
      http.send()
      if (http.status === 200 && url) {
        this.image = `${process.env.VUE_APP_API_BASE}/storage/image/${url}`
      } else {
        this.image = `${process.env.VUE_APP_API_BASE}/public/assets/avatar-1577909.svg`
      }
    },
    updateUser() {
      this.$refs.userEdit.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('name', this.user.name)
          formData.append('cpf', this.user.cpf)
          formData.append('others', this.user.others)
          formData.append('crm', this.user.crm)
          formData.append('crm_uf', this.user.crm_uf)
          formData.append('profession', this.user.profession)
          formData.append('photo', this.user.photo)
          formData.append('phone', this.user.phone)
          formData.append('birthDate', this.formatDate(this.user.birthDate))
          formData.append('cep', this.user.cep)
          formData.append('street', this.user.street)
          formData.append('number', this.user.number)
          formData.append('city', this.user.city)
          formData.append('neighborhood', this.user.neighborhood)
          formData.append('state', this.user.state)
          formData.append('country', this.user.country)
          formData.append('complemento', this.user.complemento)

          this.ActionUpdateUser(formData).then(async (api) => {
            switch (api.data.status) {
              case 200:
                await this.ActionSetUser(api.data.response.user)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: api.data.message,
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                this.imgSrc = null
                break
              case 400:
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: api.data.message,
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
                this.imgSrc = null
                break
              default:
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: api.data.message,
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
                this.imgSrc = null
            }
          })
        }
      })
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.dataURItoBlob(this.cropImg)
    },
    dataURItoBlob(dataURI) {
      // conversão de byte para string
      const byteString = atob(dataURI.split(',')[1])

      // separando mime type
      const mimeString = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]

      // byts para um array string
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      // nova imagem cortada
      this.user.photo = new Blob([ab], { type: mimeString })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Imagem cortada. Não esqueça de salvar as mudanças.',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
      this.$refs['crop-image'].hide()
    },
    flipX() {
      const dom = this.$refs.flipX
      let scale = dom.getAttribute('data-scale')
      scale = scale ? -scale : -1
      this.$refs.cropper.scaleX(scale)
      dom.setAttribute('data-scale', scale)
    },
    flipY() {
      const dom = this.$refs.flipY
      let scale = dom.getAttribute('data-scale')
      scale = scale ? -scale : -1
      this.$refs.cropper.scaleY(scale)
      dom.setAttribute('data-scale', scale)
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4)
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4)
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY)
    },
    reset() {
      this.$refs.cropper.reset()
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg)
    },
    setCropBoxData() {
      if (!this.data) return
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data))
    },
    setData() {
      if (!this.data) return
      this.$refs.cropper.setData(JSON.parse(this.data))
    },
    setImage(e) {
      const file = e.target.files[0]
      this.user.photo = file

      if (file.type.indexOf('image/') === -1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Por favor selecione uma imagem',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        // alert('Sorry, FileReader API not supported')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Formato de arquivo não suportado',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
      this.$refs['crop-image'].show()
    },
    showFileChooser() {
      this.$refs.input.click()
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent)
    },
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#name {
  margin-top: 15px;
}

.header h2 {
  margin: 0;
}
.header a {
  text-decoration: none;
  color: black;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.preview {
  width: 100%;
  overflow: hidden;
}
.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}
</style>
